import { graphql } from 'gatsby';
import React from 'react';
import Intro from '../components/Intro/Intro';
import Layout from '../components/Layout/Layout';
import './nominate-a-patient.scss';
import sprite from '../../static/sprite.svg';
import Form from '../components/Form/Form';
import Button from '../components/Button/Button';

const NominateAPatient = ({ data }) => {
  return (
    <Layout>
      <Intro
        heading={data.introText.childTextContentJson.intro.heading}
        description={data.introText.childTextContentJson.intro.description}
        imageData={data.introImage.childImageSharp.gatsbyImageData}
      />

      <section className="process-overview">
        <h2 className="process-overview__heading heading-secondary">
          {
            data.processOverviewText.childTextContentJson.processOverview
              .heading
          }
        </h2>

        {data.processOverviewText.childTextContentJson.processOverview.description1.map(
          para => (
            <p className="process-overview__description para">{para}</p>
          )
        )}

        <ul className="process-overview__list para">
          {data.processOverviewText.childTextContentJson.processOverview.listItems1.map(
            item => (
              <li
                className="process-overview__list-item"
                dangerouslySetInnerHTML={{ __html: item }}
              ></li>
            )
          )}
        </ul>
        {data.processOverviewText.childTextContentJson.processOverview.description2.map(
          para => (
            <p className="process-overview__description para">{para}</p>
          )
        )}

        <ul className="process-overview__list para">
          {data.processOverviewText.childTextContentJson.processOverview.listItems2.map(
            item => (
              <li className="process-overview__list-item">{item}</li>
            )
          )}
        </ul>
        <p
          className="process-overview__footnote"
          dangerouslySetInnerHTML={{
            __html:
              data.processOverviewText.childTextContentJson.processOverview
                .footNote,
          }}
        ></p>
        <span
          className={`process-overview__icon-box process-overview__icon-box--1`}
        >
          <svg className="process-overview__icon">
            <use href={`${sprite}#site-graphic`} />
          </svg>
        </span>
        <span
          className={`process-overview__icon-box process-overview__icon-box--2`}
        >
          <svg className="process-overview__icon">
            <use href={`${sprite}#site-graphic`} />
          </svg>
        </span>
      </section>
      {/* <Form /> */}

      <div className="process-overview__button">
        <Button
          label="Nominate a Patient"
          onClick={() =>
            (window.location.href = 'https://forms.office.com/e/MVqrcQNxs6')
          }
        />
      </div>
    </Layout>
  );
};

export default NominateAPatient;

export const query = graphql`
  query NominatePatientQuery {
    introText: file(relativePath: { eq: "nominate-a-patient.json" }) {
      childTextContentJson {
        intro {
          heading
          description
        }
      }
    }
    introImage: file(relativePath: { eq: "nominate-a-patient.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    processOverviewText: file(relativePath: { eq: "nominate-a-patient.json" }) {
      childTextContentJson {
        processOverview {
          description1
          description2
          footNote
          heading
          listItems1
          listItems2
        }
      }
    }
  }
`;
